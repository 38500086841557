import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Layout } from "@layouts/default"
import { Hero } from "@components/hero/hero"
import { SEO } from "@components/seo"
import { Accordion } from "@components/accordion/accordion"

import { privacyPagesStyles } from "@styles/pages/privacy-pages/privacy-pages.styles"

const CookiesPage = () => {
  const accordionData = [
    {
      title: "Do I need programming skills to use Acme Tool?",
      content: (
        <p>
          Change the color to match your brand or vision, add your logo, choose
          the perfect thumbnail, remove the playbar, add speed controls, and
          more. <strong>Increase engagement with CTAs</strong> and custom end
          screens, or keep your video private and password-protected.
        </p>
      ),
    },
    {
      title: "How many projects I can do with Acme Tool?",
      content: (
        <p>
          Remove the playbar, add speed controls, and more. Increase engagement
          with CTAs and custom end screens, or keep your video private and
          password-protected.
        </p>
      ),
    },
  ]

  return (
    <Layout>
      <SEO
        title="Our Approach to Cookies"
        description=""
        meta={[
          { name: "robots", content: "noindex" },
          { name: "robots", content: "nofollow" },
        ]}
      />
      <Hero title="Our Approach to Cookies" isSmall={true} />
      <section css={privacyPagesStyles.accordionSection}>
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Accordion items={accordionData} />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default CookiesPage
